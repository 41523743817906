@import 'variables';
@import 'typography';

.section {
  &__block-heading {
    @include typo-h1;

    display: block;
    margin-bottom: 24px;
    color: $raisin-black;
    text-decoration: none;
  }

  &__heading-small {
    @include typo-h2;

    display: block;
    margin-bottom: 16px;
    color: $raisin-black;
    text-decoration: none;
  }

  & + .section {
    margin-top: 80px;
  }

  &__block {
    display: block;

    & + .section__block {
      margin-top: 48px;
    }
  }

  &__panel {
    padding: 24px;
    background-color: $white-smoke;
    border-radius: 24px;

    & + .section__panel {
      margin-top: 32px;
    }
  }
}

.new-design {
  .section {
    &__heading {
      margin-bottom: 48px;
      color: $dark;
    }

    &__heading-icon {
      margin-right: 16px;
      color: $primary-color;
    }

    &__block-heading {
      @include typo-h2;

      margin-bottom: 24px;
      color: $dark;
    }

    &__heading-small {
      @include typo-h2;

      margin-bottom: 24px;
    }

    &__panel-heading {
      margin-bottom: 24px;
      font-size: 17px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.2px;
    }

    &__panel {
      padding: 24px;
      background-color: $white;
      box-shadow: 0 8px 24px 2px $black--4;
      border-radius: 8px;

      & + .section__panel {
        margin-top: 30px;
      }
    }

    &__toggle-header {
      position: relative;
      padding-top: 8px;
      padding-right: 32px;
      padding-bottom: 8px;
      transition: 0.2s padding;
      cursor: pointer;

      .section__heading-small {
        margin-bottom: 0;
      }

      &:hover {
        .section__toggle-icon {
          color: $granite-gray;
        }
      }

      &--open {
        padding-bottom: 24px;

        .section__toggle-icon {
          transform: rotate(180deg);
        }
      }
    }

    &__toggle-icon {
      position: absolute;
      top: 6px;
      right: 0;
      width: 24px;
      height: 24px;
      color: $gray;
      transform: rotate(0);
      transition: 0.2s transform, 0.3s color;
    }
  }
}

.new-material-design {
  .section {
    &__heading {
      margin-bottom: 48px;
      color: inherit;
    }

    &__block-heading {
      @include typo-h2;

      margin-bottom: 16px;
      color: inherit;
    }

    &__heading-small {
      @include typo-h2;

      margin-bottom: 24px;
    }
  }
}
