/* this file will be extracted to main dist folder and is imported in index.html */

/* design system */
@import 'design-system-lib/src/styles/styles';
@import './variables';

@import './common/scroll.scss';
@import './common/grid.scss';
@import './common/section.scss';
@import './common/loading.scss';
@import './common/page.scss';
@import './common/dragula.scss';

/* our libs */
@import 'flipchart/src/styles/styles';
@import 'chat/src/styles/styles';

/* other libs */
@import 'nouislider/dist/nouislider.min.css';
@import 'medium-editor/dist/css/medium-editor.min.css';
@import 'dragula/dist/dragula.css';
@import '@angular/cdk/overlay-prebuilt.css';

@import '@angular/cdk/overlay-prebuilt.css';

html {
  width: 100%;
  font-size: 1px;
}

:root {
  --primary-color: #1071ff;
  --primary-color-rgb: 16, 133, 255;
}

body {
  position: relative;
  width: 100%;
  color: $dark;
  overflow: auto;

  @include typo-body;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.text-link {
  color: $primary-color;

  &:hover {
    color: #{rgba($primary-color-rgb, 0.64)};
  }
}

@media print {
  html {
    font-size: 0.661px;
  }
}
