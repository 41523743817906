@import 'variables';

.gu-mirror {
  opacity: 1;
  box-shadow: $modal-shadow;

  * {
    pointer-events: auto;
    cursor: grabbing;
  }
}
