@import 'variables.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $black--22;
  border-radius: 24px;
}

*:hover {
  &::-webkit-scrollbar-thumb {
    background-color: $black--54;
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

.stop-scroll {
  overflow: hidden;
}

/* perfect scrollbar */
.ps {
  .ps__rail-x.ps--clicking,
  .ps__rail-x:focus,
  .ps__rail-x:hover,
  .ps__rail-y.ps--clicking,
  .ps__rail-y:focus,
  .ps__rail-y:hover {
    background-color: transparent;
    opacity: 1;
  }
}

.ps__rail-y {
  z-index: 250;
  display: block;
  width: 8px;
  opacity: 1;
}

.ps__thumb-y {
  width: 8px;
  background-color: $black--22;
  border-radius: 8px;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  width: 8px;
  background-color: $black--54;
}
