@import 'variables';
@import 'typography';

/*
  Главный класс страницы.
*/

.page {
  --header-height: 64px;
  // Для широких мониторов всегда 4 колонки.
  --cards-grid-columns: repeat(4, 1fr);

  display: block;

  &--no-header {
    margin-top: 40px;
  }

  &--full-filing {
    display: flex;
    flex-direction: column;
    height: 100%;

    & .page__main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__cover {
    position: relative;
    margin-bottom: 48px;
    padding: 0 32px 40px;
    background-color: rgba(0, 0, 0, 0.38);
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.38);
      z-index: -1;
    }

    & .page__header {
      margin: 0;
    }
  }

  &__breadcrumbs {
    padding-top: 40px;
    padding-bottom: 24px;
  }

  &__header {
    position: sticky;
    top: 40px;
    margin: 0 -8px;
    padding: 0 8px;
    background-color: $white;
    z-index: 100;

    &--no-sticky {
      position: static;
      top: 0;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    & + .page__filter {
      margin-top: 8px;
    }
  }

  &__tabs {
    margin-bottom: 8px;
  }

  &__widgets {
    margin-bottom: 32px;
  }

  &__filter {
    width: 100%;

    &--margin {
      margin-top: 0;
    }
  }

  &__main {
    margin-top: 40px;

    &--with-cover {
      margin-top: 48px;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    flex: 1;
    gap: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 754px;
    min-width: 400px;
  }

  &__aside {
    flex-basis: 280px;
  }

  &__table {
    flex-grow: 1;
    min-height: 500px;
  }

  &__empty-placeholder {
    margin-top: 135px;
  }

  &__empty-placeholder-btn {
    margin-top: 32px;
  }

  &__download-fixed-btn {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 250;

    & button {
      width: 40px;
      height: 40px;
    }

    &--with-chat,
    &--with-note {
      bottom: 72px;
    }

    &--with-chat {
      &.page__download-fixed-btn--with-note {
        bottom: 128px;
      }
    }
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    & li {
      display: inline-flex;
    }
  }

  &__cards-grid {
    display: grid;
    grid-template-columns: var(--cards-grid-columns);
    grid-column-gap: 24px;
    grid-row-gap: 32px;
  }
}

/* TODO: Временно пока шапка не фиксированного размера */
@media screen and (max-width: 900px) {
  .page {
    --header-height: 40px;
  }
}

@media (width <= 1366px) {
  // Для узких мониторов (ширина, при которой пропадает меню навигации) - колонки адаптивные.
  // Стараемся вместить максимум колонок, при условии, что ширина каждой не меньше 250 пикселей.
  .page {
    --cards-grid-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}