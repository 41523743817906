.grid {
  &__row {
    display: flex;
    // width: 100%;
    margin: 0 -15px 30px;
  }

  &__list {
    flex-wrap: wrap;
    display: flex;
    margin: -15px;
  }

  &__column {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    // display: flex;
    // flex-direction: column;
    &--1-3 {
      width: 33.333333%;
    }

    &--2-3 {
      width: 66.666666%;
    }

    &--1-2 {
      width: 50%;
    }
  }

  &__item {
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 100%;
    padding: 15px;

    &--flex {
      display: flex;
    }

    &--full-width {
      width: 100%;
    }

    &--1-3 {
      width: 33.333333%;
      max-width: 33.333333%;
    }

    &--2-3 {
      max-width: 66.666666%;
    }

    &--1-2 {
      max-width: 50%;
    }
  }
}

@media screen and (max-width: 900px) {
  .grid {
    &__list {
      margin: -8px;
    }

    &__item {
      padding: 8px;

      &--1-3 {
        width: 50%;
        max-width: 50%;
      }

      &--1-2,
      &--2-3 {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .grid {
    &__list {
      margin: -8px;
    }

    &__item {
      padding: 8px;

      &--1-3 {
        width: 100%;
        max-width: 100%;
        min-width: auto;
      }
    }
  }
}
