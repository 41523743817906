@import 'variables';
@import 'animations';

.loading {
  position: absolute;
  top: 50vh;
  left: 50%;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  z-index: 200;
  display: flex;
  width: 40px;
  height: 40px;
  user-select: none;
  transform: translate(-50%, -50%);

  &--init {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1605;
    width: auto;
    height: auto;
    transform: none;
    background-color: $white;
  }

  &--block {
    position: relative;
  }

  &--static {
    position: static;
    transform: none;
  }

  &--static-center {
    position: static;
    margin: auto;
    transform: none;
  }

  &--small {
    width: 24px;
    height: 24px;
  }

  &--smaller {
    width: 20px;
    height: 20px;
  }

  &__spinner {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/icons/icon-loading.svg');
    background-repeat: no-repeat;
    animation: 0.4s infinite linear rotate;
    vertical-align: middle;

    &--init {
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    margin-top: 24px;
    color: $primary-color;
    font-size: 17px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: center;
  }
}
