/*
* Заново подключаем переменные из дизайн системы, так как имя файла одинаковое с cms variables и ведет сюда.
* Переименовывать файл нельзя,так как придется вручную добавлять новый файл в места где используются старые cms переменные цветов.
* Удалить файл после замены всех цветов и variables из дизайн системы будут тянуться автоматически из подключенного файлы styles
*/
@import 'design-system-lib/src/styles/variables';

/* old cms colors */
$raisin-black: #222;
$granite-gray: #646464;
$taupe-gray: #909090;
$gray: #bcbcbc;
$white-smoke: #f6f6f6;
$outer-space: #3e474f;
